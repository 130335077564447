<mat-sidenav-container class="loading-layout" [hasBackdrop]="handsetBreakpoint">
  <mat-sidenav class="mat-elevation-z2" [mode]="handsetBreakpoint ? 'over': 'side'" #start [opened]="!handsetBreakpoint">
    <div class="nav-header">
      <div class="logo"></div>
    </div>
    <mat-nav-list>
      <ng-container #menuList *ngFor="let group of displayMenuItems; let i = index">
        <h3 mat-subheader *ngIf="group.groupTitle">{{group.groupTitle.toUpperCase()}}</h3>
        <a [routerLink]="item.link" class="nav-item" *ngFor="let item of group.items; let j = index;" mat-list-item
        (click)="handsetBreakpoint && start.toggle()"
          routerLinkActive="selected">
          <mat-icon>{{item.icon}}</mat-icon>
          <span class="m-l-10">{{item.title}} <span *ngIf="item.count" class="badge">{{item.count}}</span></span>
        </a>
      </ng-container>

    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      <mat-toolbar-row class="header">
        <button (click)="start.toggle()" mat-icon-button>
          <mat-icon>menu</mat-icon>
        </button>
        <!--<span class="m-l-5">Document</span>-->
        <div class="mat-search">
          <input @search [formControl]="searchControl" *ngIf="searchEnabled"
            placeholder="{{'top_menu.search' | translate}}" type="text" (keyup.enter)="showChange($event.target.value)">
        </div>

        <button mat-icon-button [matMenuTriggerFor]="menuNotification" (menuOpened)="getNotification()" (menuClosed)="clearNotification()">
          <mat-icon [matBadge]="notificationUnread" [matBadgeHidden]="notificationUnread == 0" matBadgeColor="warn" matBadgePosition="before">
            notifications
          </mat-icon>
        </button>
        <mat-menu #menuNotification class="notification-panel" xPosition="before" yPosition="below">
          <div class="notification-container">
              <h3 class="title">
                  {{'label.notification' | translate}}
                  <button mat-button color="primary" aria-label="Mark all as read"
                      (click)="markNotificationAllAsRead()">
                      {{'label.mark_all_as_read' | translate}}
                  </button>
              </h3>
              <div class="notification-content">
                  <ul>
                      <li *ngFor="let item of notifications" (click)="markNotificationAsRead(item._id!)">
                          <ng-container *ngIf="item.doc_id; else notificationItem">
                              <a [routerLink]="'documents/pending/detail/' + item.doc_id">
                                  <ng-template [ngTemplateOutlet]="notificationItem">
                                  </ng-template>
                              </a>
                          </ng-container>
                          <ng-template #notificationItem>
                              <div class="notification-item" [ngClass]="{'unread': !item.is_read}">
                                  <div class="content">
                                      <section class="message">
                                         {{item.message}}
                                      </section>
                                      <p class="time">{{item.date | date:'dd/MM/yyyy, h:mm:ss a'}}</p>
                                  </div>
                              </div>
                          </ng-template>
                      </li>
                      <li *ngIf="notifications.length>0 && notifications.length < notificationTotal">
                        <button class="btn-view-more" (click)="$event.stopPropagation(); getNotification()" mat-button>{{'label.view_more' | translate}}</button>
                      </li>
                  </ul>
              </div>
          </div>
      </mat-menu>
        <div class="user-profile">
          <button mat-button routerLink="/users/profile">
            <mat-icon>account_circle</mat-icon> {{profileName | titlecase}}
          </button>
        </div>

        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <!-- <button mat-menu-item [matMenuTriggerFor]="languages">
            <mat-icon>language</mat-icon>
            {{'top_menu.languages.title' | translate}}
          </button> -->
          <button class="user-profile" routerLink="/users/profile" mat-menu-item>
            <mat-icon>account_circle</mat-icon>
            {{profileName | titlecase}}
          </button>
          <button (click)="openDialogChangePassword()" mat-menu-item>
            <mat-icon>vpn_key</mat-icon>
            {{'top_menu.change_password' | translate}}
          </button>
          <button (click)="signOut()" mat-menu-item>
            <mat-icon>exit_to_app</mat-icon>
            {{'top_menu.sign_out' | translate}}
          </button>
        </mat-menu>

        <mat-menu #languages="matMenu">
          <button mat-menu-item (click)="switchLanguage('kh')"><img src="./../../../../assets/images/khmer.png"
              class="flag-size" /> {{'top_menu.languages.khmer' | translate}}</button>
          <button mat-menu-item (click)="switchLanguage('en')"><img src="./../../../../assets/images/english.png"
              class="flag-size" /> {{'top_menu.languages.english' | translate}}</button>
        </mat-menu>

      </mat-toolbar-row>
    </mat-toolbar>
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>