import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationsResponse } from '../models/responses/notifications.response';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CommonResponse } from '../models/responses/common.response';

@Injectable({ providedIn: 'root' })
export class NotificationService {

  private notificationCount$ = new BehaviorSubject({ unread: 0, total: 0 });
  path = environment.baseUrl + "notification_log"
  constructor(private http: HttpClient) {

  }

  public getNotificationCount(): Observable<{ unread: number, total: number }> {
    return this.notificationCount$.asObservable();
  }

  public refreshNotification() {
    return this.getCount()
      .subscribe(value => this.notificationCount$.next(value));
  }

  public getAll(currentPage = 1, limit = 9999): Observable<NotificationsResponse> {
    return this.http.get<NotificationsResponse>(this.path, {
      params: {
        page: currentPage.toString(),
        limit: limit.toString()
      }
    });
  }
  public getCount() {
    return this.http.get<{ status: number, unread: number, total: number }>(`${this.path}/count`);
  }

  public markAsRead(id: string) {
    return this.http.post<CommonResponse>(`${this.path}/read/${id}`, {});
  }
  public markAllAsRead() {
    return this.http.post<CommonResponse>(`${this.path}/mark-as-read-all`, {});
  }

}
